import React, { useState } from 'react';
import ToggleSwitch from '../shared/ToggleSwitch';
import { formatDateWithYear } from '../../utils/formatDateWithYear';
import { phoneType, completePhoneNumber, addressType, completeAddress } from '../../utils/contactUtils';
import MasterDebtorLink from './MasterDebtorLink';
const AdminCustomerInfoTableBody = ({ customerData, customerPhones, customerAddresses, effectiveConcentrationLimit, selectCustomerHandler, deselectCustomerHandler, approveCustomerHandler, pendingCustomerHandler, isRelatedPartyDebtorHandler, isNotRelatedPartyDebtorHandler, isGovernmentDebtorHandler, isNotGovernmentDebtorHandler, isMajorCorporationDebtorHandler, isNotMajorCorporationDebtorHandler, ignoreCustomerDebtorThresholdHandler, unignoreCustomerDebtorThresholdHandler, isRequestPending, enableActions, debtorBalance, checkIcon, isManualLedger, debtorLimit, concentrationLimit }) => {
    var _a;
    const onConfirmationText = `Are you sure you want to turn ${customerData.name} on?`;
    const offConfirmationText = `Are you sure you want to turn ${customerData.name} off?`;
    const selectConfirmationText = `Are you sure you want to select ${customerData.name}?`;
    const deselectConfirmationText = `Are you sure you want to deselect ${customerData.name}?`;
    const relatedDebtorText = `Are you sure you want to assign ${customerData.name} as Related Party Debtor?`;
    const notRelatedDebtorText = `Are you sure you want to unassign ${customerData.name} as Related Party Debtor?`;
    const governmentDebtorText = `Are you sure you want to assign ${customerData.name} as Government Debtor?`;
    const notGovernmentDebtorText = `Are you sure you want to unassign ${customerData.name} as Government Debtor?`;
    const majorCorporationDebtorText = `Are you sure you want to assign ${customerData.name} as Major Corporation Debtor?`;
    const notMajorCorporationDebtorText = `Are you sure you want to unassign ${customerData.name} as Major Corporation Debtor?`;
    const [isRelatedPartyDebtor, setIsRelatedPartyDebtor] = useState(customerData.isRelatedPartyDebtor);
    const [isGovernmentDebtor, setIsGovernmentDebtor] = useState(customerData.isGovernmentDebtor);
    const [isMajorCorporationDebtor, setIsMajorCorporationDebtor] = useState(customerData.isMajorCorporationDebtor);
    return (React.createElement("tbody", null,
        customerData.sourceId && (React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "Customer Accn"),
            React.createElement("td", { className: 'value' }, customerData.sourceId))),
        React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "Debtor Balance"),
            React.createElement("td", { className: 'value' }, debtorBalance)),
        React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "Master Debtor"),
            React.createElement("td", { className: 'value' },
                React.createElement(MasterDebtorLink, { ...{ customerData, checkIcon } }))),
        React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "Last Fetched At"),
            React.createElement("td", { className: 'value' }, formatDateWithYear(customerData.lastFetchedAt || customerData.createdAt))),
        React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "ABN"),
            React.createElement("td", { className: 'value' }, customerData.abn)),
        React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "Status"),
            React.createElement("td", { className: 'value' },
                React.createElement("div", { className: 'text' }, customerData.earlypayStatus))),
        React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "Debtor Threshold Status"),
            React.createElement("td", { className: 'value' },
                React.createElement("div", null,
                    React.createElement("div", { className: 'text' },
                        (customerData.debtorThresholdStatus === 'ABOVE' ||
                            customerData.debtorThresholdStatus === 'BELOW') && (React.createElement("a", { className: 'custom-link -blue -margin-right', onClick: ignoreCustomerDebtorThresholdHandler }, "Ignore")),
                        customerData.debtorThresholdStatus === 'IGNORED' && (React.createElement("a", { className: 'custom-link -blue -margin-right', onClick: unignoreCustomerDebtorThresholdHandler }, "Unignore")),
                        customerData.debtorThresholdStatus)))),
        React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "Contact Name"),
            React.createElement("td", { className: 'value' }, customerData.contactName)),
        React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "Email"),
            React.createElement("td", { className: 'value' }, (_a = customerData.email) === null || _a === void 0 ? void 0 : _a.split(',').map(str => str.trim()).join(', '))),
        customerPhones.length !== 0 &&
            customerPhones.map((phone) => {
                return (React.createElement("tr", { key: phone.id },
                    React.createElement("td", { className: 'vertical' }, phoneType(phone.kind)),
                    React.createElement("td", { className: 'value' }, completePhoneNumber(phone))));
            }),
        customerAddresses.length !== 0 &&
            customerAddresses.map((address) => {
                return (React.createElement("tr", { key: address.id },
                    React.createElement("td", { className: 'vertical' }, addressType(address.kind)),
                    React.createElement("td", { className: 'value' }, completeAddress(address))));
            }),
        React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "Select"),
            React.createElement("td", { className: 'value' },
                React.createElement(ToggleSwitch, { isToggled: customerData.isSelected, switchOn: () => window.confirm(selectConfirmationText) && selectCustomerHandler(), switchOff: () => window.confirm(deselectConfirmationText) &&
                        deselectCustomerHandler(), isRequestPending: isRequestPending, isSwitchDisabled: !enableActions }))),
        React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "Approve"),
            React.createElement("td", { className: 'value' },
                React.createElement(ToggleSwitch, { isToggled: customerData.earlypayStatus === 'APPROVED', switchOn: () => window.confirm(onConfirmationText) && approveCustomerHandler(), switchOff: () => window.confirm(offConfirmationText) && pendingCustomerHandler(), isRequestPending: isRequestPending, isSwitchDisabled: !enableActions }))),
        isManualLedger ? (React.createElement(React.Fragment, null,
            React.createElement("tr", null,
                React.createElement("td", { className: 'vertical' }, "Debtor Limit ($)"),
                React.createElement("td", { className: 'value' }, debtorLimit)),
            React.createElement("tr", null,
                React.createElement("td", { className: 'vertical' }, "Concentration Limit (%)"),
                React.createElement("td", { className: 'value' }, concentrationLimit)))) : (React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "Effective Concentration Limit ($)"),
            React.createElement("td", { className: 'value' }, effectiveConcentrationLimit))),
        React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "Related Party Debtor"),
            React.createElement("td", { className: 'value' },
                React.createElement(ToggleSwitch, { isToggled: isRelatedPartyDebtor, switchOn: () => {
                        if (window.confirm(relatedDebtorText)) {
                            isRelatedPartyDebtorHandler();
                            setIsRelatedPartyDebtor(!isRelatedPartyDebtor);
                        }
                    }, switchOff: () => {
                        if (window.confirm(notRelatedDebtorText)) {
                            isNotRelatedPartyDebtorHandler();
                            setIsRelatedPartyDebtor(!isRelatedPartyDebtor);
                        }
                    }, isRequestPending: isRequestPending, isSwitchDisabled: !enableActions }))),
        React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "Government Debtor"),
            React.createElement("td", { className: 'value' },
                React.createElement(ToggleSwitch, { isToggled: isGovernmentDebtor, switchOn: () => {
                        if (window.confirm(governmentDebtorText)) {
                            isGovernmentDebtorHandler();
                            setIsGovernmentDebtor(!isGovernmentDebtor);
                        }
                    }, switchOff: () => {
                        if (window.confirm(notGovernmentDebtorText)) {
                            isNotGovernmentDebtorHandler();
                            setIsGovernmentDebtor(!isGovernmentDebtor);
                        }
                    }, isRequestPending: isRequestPending, isSwitchDisabled: !enableActions }))),
        React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "Major Corporation Debtor"),
            React.createElement("td", { className: 'value' },
                React.createElement(ToggleSwitch, { isToggled: isMajorCorporationDebtor, switchOn: () => {
                        if (window.confirm(majorCorporationDebtorText)) {
                            isMajorCorporationDebtorHandler();
                            setIsMajorCorporationDebtor(!isMajorCorporationDebtor);
                        }
                    }, switchOff: () => {
                        if (window.confirm(notMajorCorporationDebtorText)) {
                            isNotMajorCorporationDebtorHandler();
                            setIsMajorCorporationDebtor(!isMajorCorporationDebtor);
                        }
                    }, isRequestPending: isRequestPending, isSwitchDisabled: !enableActions }))),
        React.createElement("tr", null,
            React.createElement("td", { className: 'vertical' }, "Disclose Type"),
            React.createElement("td", { className: 'value -capitalize' }, customerData.discloseType))));
};
export default AdminCustomerInfoTableBody;
