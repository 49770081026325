import fetch from 'isomorphic-fetch';
export const updateRegionOwner = async (apiToken, borrowerId, body) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/region_owner`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const updateProductDetails = async (apiToken, borrowerId, body) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/product_details`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const createIndustry = async (apiToken, borrowerId, body) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/industries`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const updateIndustry = async (apiToken, borrowerId, industryId, body) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/industries/${industryId}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const updateTradingTerms = async (apiToken, borrowerId, body) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/trading_terms`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const updateInsuranceStatus = async (apiToken, borrowerId, body) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/insurance_status`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const updateExcessReceipt = async (apiToken, borrowerId, body) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/excess_receipt`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
updateExcessReceipt;
export const updateDebtorAgeingThreshold = async (apiToken, borrowerId, body) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/debtor_ageing_threshold`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const updateDebtorConcentrations = async (apiToken, borrowerId, body) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/debtor_concentrations`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const updateReserveAmount = async (apiToken, companyId, body) => {
    const endpoint = `/api/admin/companies/${companyId}/reserve_amount`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const getNavigationData = async (apiToken, borrowerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/general_client_setup/nav_data`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const updateDebtorStatus = async (apiToken, borrowerId, body) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/debtor_status`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const updateAmendedInvoiceThreshold = async (apiToken, borrowerId, body) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/amended_invoice_threshold`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
